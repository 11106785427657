import {Avatar, Box, Skeleton, Typography, useTheme} from '@mui/material';
import {CampaignOverview} from '../../types';
import {CampaignStatus, getCampaignStatus} from '../../utils/campaign';
import GroupsIcon from '@mui/icons-material/Groups';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {Colors} from '../common/colors';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TagElement from './tag';
import {ImportantLink, MyLink} from '../common/button';
import {HomeTitle} from '../common/text';
import LikeButton from './like';
import TimeLeft from './campaignTimeLeft';
import Indicator from './indicator';
import {useResponsive} from '../../provider/MediaQueryProvider';
import Link from 'next/link';
import {getLocaleLabels} from '../../utils/lang';
import {RATIO_IMAGE} from '../../utils/settings';
import Image from 'next/image';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import {useTranslation} from 'next-i18next';


type CampaignHomeProps = {
    campaign: CampaignOverview;
};

const IMG_WIDTH_DESKTOP = 640;
const IMG_WIDTH_MOBILE = 320;

export default function CampaignHome(props: CampaignHomeProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {t, i18n} = useTranslation('common');
    const {isMobile, isTablet, isLaptop} = useResponsive();

    // console.log('CAMPAIGN = ', data);
    if (!props.campaign) {
        return <></>;
    }

    const campaign = props.campaign;

    const status = getCampaignStatus(campaign);
    const enabled = status == CampaignStatus.PENDING || status == CampaignStatus.EXTRA;

    if (!enabled) {
        return <></>;
    }

    const labels = getLocaleLabels(campaign.labels, i18n.language);
    const picture = labels.cover_url;
    const percentage = Math.round(
        campaign.goal > 0 ? (campaign.amount / campaign.goal) * 100 : 0
    );

    const authorPicture = campaign.author.picture_url;
    // console.log('campaign = ', campaign);
    // console.log('authorPicture = ', authorPicture);

    const blocDirection = isLaptop || isTablet || isMobile ? 'column' : 'row';
    const itemDirection = isTablet || isMobile ? 'column' : 'row';
    const imgWidth = isTablet || isMobile ? IMG_WIDTH_MOBILE : IMG_WIDTH_DESKTOP;

    return (
        <Box sx={{paddingTop: isMobile ? '25px' : '50px', paddingBottom: isMobile ? '25px' : '50px'}}>
            {/* <HomeTitle>{t('title.first')}</HomeTitle> */}
            <Box display="flex" flexDirection="row" justifyContent="center">
                <Box
                    display="flex"
                    flexDirection={blocDirection}
                    justifyContent="center"
                    sx={{
                        WebkitBoxShadow: Colors.shadow,
                        boxShadow: Colors.shadow,
                        borderRadius: '8px',
                    }}
                >
                    {Boolean(picture) && (
                        <Image
                            src={picture}
                            alt={labels.title}
                            width={imgWidth}
                            height={Math.floor(imgWidth / RATIO_IMAGE)}
                            style={{
                                borderTopLeftRadius: '8px',
                                borderBottomLeftRadius:
                                    isLaptop || isTablet || isMobile ? '0px' : '8px',
                                borderTopRightRadius:
                                    isLaptop || isTablet || isMobile ? '8px' : '0px',
                            }}
                            priority
                        />
                    )}
                    {!Boolean(picture) && (
                        <HideImageOutlinedIcon
                            sx={{
                                width: imgWidth,
                                height: Math.floor(imgWidth / RATIO_IMAGE),
                                display: 'block',
                                margin: 'auto',
                                borderTopLeftRadius: '8px',
                                borderBottomLeftRadius:
                                    isLaptop || isTablet || isMobile ? '0px' : '8px',
                                borderTopRightRadius:
                                    isLaptop || isTablet || isMobile ? '8px' : '0px',
                            }}
                        />
                    )}
                    <Box
                        padding={'15px'}
                        sx={{
                            borderBottomLeftRadius:
                                isLaptop || isTablet || isMobile ? '8px' : '0px',
                            borderTopRightRadius:
                                isLaptop || isTablet || isMobile ? '0px' : '8px',
                            borderBottomRightRadius: '8px',
                            width: imgWidth,
                            backgroundColor: isDark ? Colors.dark : 'white',
                        }}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                    >
                        <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <LikeButton
                                campaignId={campaign.campaign_id}
                                campaignUrl={campaign.url}
                            />
                        </Box>
                        <Box
                            sx={{
                                marginLeft: isMobile || isTablet ? '10px' : '40px',
                                marginRight: isMobile || isTablet ? '10px' : '40px',
                            }}
                            display="flex"
                            flex="1"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Box
                                display="flex"
                                flexDirection={isTablet || isMobile ? 'column' : 'row'}
                                marginTop={'10px'}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box display="flex" flexDirection="row">
                                    <Link
                                        href={'/author/' + campaign.author.url}
                                        style={{textDecoration: 'none'}}
                                    >
                                        <Avatar
                                            alt={campaign.author.display_name}
                                            src={authorPicture}
                                            imgProps={{referrerPolicy: 'no-referrer'}}
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                marginRight: '5px',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Link>
                                    <MyLink
                                        href={'/author/' + campaign.author.url}
                                        title={campaign.author.display_name}
                                        sx={{maxWidth: {sx: '250px', md: '300px'}}}
                                    />
                                </Box>
                                <Box display="flex" flexDirection="row" alignSelf="center">
                                    <LocalOfferIcon
                                        sx={{marginRight: '5px', fontSize: '32px'}}
                                    />
                                    {campaign.tags.map((t) => (
                                        <TagElement
                                            tag={t.tag}
                                            key={t.tag.tag_id}
                                            sx={{sx: '250px', md: '150px'}}
                                        />
                                    ))}
                                </Box>
                            </Box>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginTop: '10px',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {labels.title}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'justify',
                                    marginTop: '10px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                }}
                            >
                                {labels.subtitle}
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection={itemDirection}
                                justifyContent="space-evenly"
                                marginTop={'10px'}
                            >
                                <Indicator
                                    text={campaign.contribution.toString()}
                                    icon={
                                        <GroupsIcon
                                            sx={{alignSelf: 'center', color: Colors.first}}
                                            fontSize="large"
                                        />
                                    }
                                />
                                <TimeLeft campaign={campaign} />
                                {/* <Typography>{campaign.amount} € {t('word.over')} {campaign.goal} €</Typography> */}
                            </Box>
                            <Box
                                marginTop='10px'
                                marginBottom='25px'
                                marginLeft={isMobile || isTablet ? '0px' : '40px'}
                                marginRight={isMobile || isTablet ? '0px' : '40px'}
                            >
                                <ImportantLink href={'/' + campaign.url} >
                                    {t('action.preorder')}
                                </ImportantLink>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export function CampaignHomeSkeleton() {
    const {isMobile, isTablet, isLaptop} = useResponsive();

    const width = isTablet || isMobile ? IMG_WIDTH_MOBILE : IMG_WIDTH_DESKTOP;
    const height = Math.floor(width / RATIO_IMAGE);
    const blocDirection = isLaptop || isTablet || isMobile ? 'column' : 'row';

    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '100px',
                paddingBottom: '100px',
            }}
        >
            <Skeleton variant="text" sx={{width: 200, fontSize: 35}} />
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: blocDirection,
                    alignItems: 'center',
                    paddingTop: '50px',
                }}
            >
                <Skeleton variant="rectangular" width={width} height={height} />
                <Box
                    sx={{
                        width: width,
                        height: height,
                        padding: '20px',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignitems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Skeleton variant="text" sx={{width: 100, fontSize: 16}} />
                        <Skeleton variant="text" sx={{width: 100, fontSize: 16}} />
                    </Box>
                    <Skeleton
                        variant="text"
                        sx={{
                            display: 'flex',
                            flex: 1,
                            alignSelf: 'center',
                            width: '80%',
                            margin: '25px',
                        }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            display: 'flex',
                            flex: 1,
                            alignSelf: 'center',
                            width: '80%',
                        }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            display: 'flex',
                            flex: 1,
                            alignSelf: 'center',
                            width: '80%',
                        }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            display: 'flex',
                            flex: 1,
                            alignSelf: 'center',
                            width: 300,
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            width: '60%',
                            margin: '25px',
                            alignSelf: 'center',
                        }}
                    >
                        <Skeleton variant="text" sx={{width: 50, fontSize: 16}} />
                        <Skeleton variant="text" sx={{width: 50, fontSize: 16}} />
                        <Skeleton variant="text" sx={{width: 50, fontSize: 16}} />
                    </Box>
                    <Skeleton
                        variant="rectangular"
                        sx={{width: '60%', height: 50, margin: 'auto'}}
                    />
                </Box>
            </Box>
        </Box>
    );
}
