"use client";

import {Colors} from '../common/colors';
import {getCampaignTimeLeft} from '../../utils/campaign';
import Indicator from './indicator';
import {CampaignOverview} from '../../types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {useTranslation} from 'next-i18next';


export default function TimeLeft(props: {campaign: CampaignOverview}){
    const { t } = useTranslation('common');
    const timeLeft = getCampaignTimeLeft(props.campaign);
    return (
        <Indicator
            text={timeLeft.amount.toString() + ' ' + t(timeLeft.short)}
            icon={
                <CalendarMonthIcon
                    sx={{alignSelf: 'center', color: Colors.first}}
                    fontSize="large"
                />
            }
        />
    )
}